.gallery {
	&__img {
		position: relative;
		max-width: 100%;
		height: 280px;
		z-index: 9;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__desc {
		position: absolute;
		bottom: 0;
		width: 100%;
		padding: .5rem;
		background-color: rgba(12, 76, 163, 0.7);
		color: $white;
	}
}

.wp-caption {
	img {
		max-width: 100%;
		height: auto;
	}

	figcaption {
		font-size: .875rem;
		padding: 0.35rem 1rem;
		text-align: center;
		background-color: var(--theme-color-blue-light);
	}
}

.content {
	h2, h3, h4, h5, h6 {
		margin-bottom: 1rem;
	}

	a {
		font-weight: 600;
		color: var(--theme-color-azure);
	}
}