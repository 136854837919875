.navbar {
    padding: 2rem 1rem .5rem;
    transition: all .3s ease-in-out;

    @include media-breakpoint-down(lg){
        &.navbar-image {
            .navbar-brand {
                img {
                    transition: filter .3s ease-in-out;
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    &.scrolled {
        background-color: $white;
        padding-top: 0.5rem;
        box-shadow: 0px -3px 30px #660DFF1A;

        &.navbar-image {
            .navbar-brand {
                img {
                    filter: none;
                }
            }
        }
    }
    
    @include media-breakpoint-up(lg){
        padding: .5rem 0 .5rem;
        background-color: $white;
        box-shadow: 0px -3px 30px #660DFF1A;
    }

    .navbar-toggler {
        font-size: unset;
        padding: 0.4rem 0.45rem;
        border-radius: $theme-radius;
        background-color: $white;
        border: 2px solid var(--theme-color-blue);

        .line {
            fill: none!important;
            stroke: var(--theme-color-black);
            stroke-width: 6;
            transition: stroke-dasharray .6s cubic-bezier(.4,0,.2,1),stroke-dashoffset .6s cubic-bezier(.4,0,.2,1)
        }

        .line1 {
            stroke-dasharray: 60 207;
            stroke-width: 6
        }

        .line2 {
            stroke-dasharray: 60 60;
            stroke-width: 6
        }

        .line3 {
            stroke-dasharray: 60 207;
            stroke-width: 6
        }

        &:not(.collapsed) .line1 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6
        }

        &:not(.collapsed) .line2 {
            stroke-dasharray: 1 60;
            stroke-dashoffset: -30;
            stroke-width: 6
        }

        &:not(.collapsed) .line3 {
            stroke-dasharray: 90 207;
            stroke-dashoffset: -134;
            stroke-width: 6
        }

        &:focus {
            box-shadow: none;
            outline: none;
        }
    }
    
    .navbar-collapse {
        .navbar-nav {
            @include media-breakpoint-down(lg){
                padding: 1rem 0 2rem;
                text-align: center;
            }

            .nav-item {
                padding: .9rem 2rem .7rem;

                .nav-link {
                    font-size: .875rem;
                    font-weight: 500;
                    padding: 0;
                    text-transform: uppercase;
                    color: var(--theme-color-black);
                    transition: color .2s ease-in-out;

                    &.active {
                        font-weight: 600;
                        color: var(--theme-color-azure);
                    }

                    &:hover {
                        color: var(--theme-color-azure);
                    }
                }
            }
        }

        .nav-button {
          margin-top: .5rem;
        }
    }
}