.service-box {
    border-radius: $theme-radius;
    background-color: $white;
    padding: 2.5rem 2rem;
    box-shadow: 10px 20px 40px #0A21E841;

    @include media-breakpoint-down(md){
        text-align: center;
        padding: 1.5rem 1rem 2rem;
        border-radius: $theme-radius;
        box-shadow: 0px 30px 30px #0A21E841;

        .home &{
            border-radius: 0 0 $theme-radius $theme-radius;
        }
    }
    @include media-breakpoint-up(xxl){
        padding: 2.75rem;

        &--img {
            padding: 2rem;
        }
    }

    &--border {
        border: 3px solid var(--theme-color-blue);
    }

    &__title {
        font-size: 1.5rem;
        margin-bottom: 0.75rem;
        
        @include media-breakpoint-up(sm){
            font-size: 1.75rem;
        }
        @include media-breakpoint-up(xxl){
            font-size: 2rem;
            
            &--big {
                font-size: 2.5rem;
            }
        }
    }

    &__desc {
        margin-bottom: 2rem;

        @include media-breakpoint-up(xl){
            font-size: 1.125rem;
        }
    }

    &__img {
        @include media-breakpoint-down(md){
            border-radius: $theme-radius $theme-radius 0 0;
            background-color: $white;
            padding: 1.5rem 4rem 0;
        }
    }

    &__benefits {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        font-size: 0.9375rem;
        color: var(--theme-color-gray-70);
        
        @include media-breakpoint-up(sm){
            gap: 2rem;
        }

        > div {
            display: flex;
            flex-direction: column;
            line-height: 1.2;
            align-items: center;
            text-align: center;
            width: 50%;
            
            img {
                max-width: 50px;
                max-height: 50px;
                height: auto;
                margin-bottom: 0.75rem;
            }

            @include media-breakpoint-up(sm){
                flex-direction: row;
                text-align: left;

                img {
                    margin-bottom: 0;
                    margin-right: .75rem;
                }
            }

        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 2rem;
        gap: 1.25rem;

        a {
            font-size: 1rem;
        }

        @include media-breakpoint-up(lg){
            flex-direction: row;
        }
    }
}