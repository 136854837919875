.btn {
    display: inline-block;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1.1;
    padding: .75rem 1.8rem;
    border: none;
    border-radius: $theme-radius;
    color: $white;
    box-shadow: 0px 15px 30px #660DFF31;
    background: transparent linear-gradient(354deg, #660DFF 0%, #0A21E8 100%) 0% 0% no-repeat padding-box;
    transition: transform .5s cubic-bezier(0.215, 0.610, 0.355, 1), box-shadow .5s cubic-bezier(0.215, 0.610, 0.355, 1), color .25s ease-in-out;

    &:hover,
    &:focus {
        color: $white;
        box-shadow: 0px 15px 15px -8px#660dff64;
        transform: translateY(-5px);
    }
    
    @include media-breakpoint-up(xl){
        font-size: 1.125rem;
    }

    &--border {
        display: inline-block;
        color: var(--theme-color-black);
        font-size: .875rem;
        font-weight: 500;
        text-transform: uppercase;
        background: none;
        padding: .25rem 1rem;
        background-color: $white;
        box-shadow: 0px 3px 6px #00000029;
        border: 2px solid var(--theme-color-black);
        margin-left: 2rem;
        transition: background-color .25s ease-in-out, color .25s ease-in-out;
    
        &:hover,
        &:focus {
            color: $white;
            transform: none;
            box-shadow: 0px 3px 6px #00000029;
            background-color: var(--theme-color-black);
        }
    }

    &--sm {
        font-size: .875rem;
    }

    &--lg {
        @include media-breakpoint-up(lg){
            font-size: 1.5rem;
        }
    }

    &--invert {
        background: transparent linear-gradient(5deg, #0A21E8 0%, #008CFF 100%) 0% 0% no-repeat padding-box;
    }

    &--blue {
        background: transparent linear-gradient(7deg, #008CFF 0%, #0CE8E8 100%) 0% 0% no-repeat padding-box;
    }

    &--green {
        color: var(--theme-color-blue);
        background: transparent linear-gradient(180deg,#0ccfba 0,#0edeab 100%) 0 0 no-repeat padding-box;
    }

    &--aqua {
        color: var(--theme-color-blue);
        background: transparent linear-gradient(180deg,#0CE8E8 0,#0CE8E8 100%) 0 0 no-repeat padding-box;

        &:hover,
        &:focus {
            color: var(--theme-color-blue);
        }
    }

    &--shadow {
        box-shadow: 0px 15px 40px #660DFF83;
    }

    &--shadowless {
        box-shadow: none;
    }
}

.link {
    display: inline-block;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--theme-color-blue);
    text-decoration: underline;
    transition: color .25s ease-in-out;

    @include media-breakpoint-up(xl){
        font-size: 1.125rem;
    }

    &:hover {
        text-decoration: underline;
        color: var(--theme-color-black);
    }

    &--arrow {
        text-decoration: none;

        &::after {
            display: inline-block;
            vertical-align: middle;
            margin-left: 0.75rem;
            height: 23px;
            content: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.215 0L6.767 1.448L12.497 7.188H0V9.242H12.5L6.77 14.982L8.218 16.43L16.433 8.215L8.215 0Z' fill='%230A21E8'/%3E%3C/svg%3E%0A");
            transition: transform .5s cubic-bezier(0.165, 0.84, 0.44, 1);
            transform-origin: center;
        }

         &:hover {
            text-decoration: none;
            color: var(--theme-color-black);

            &::after {
                transform: translateX(6px);
            }
        }

    }

    &--list {
        font-size: 1.5rem;
        font-weight: 600;
        background-color: transparent;
        border: none;
        text-decoration: none;
        margin-top: -1.5rem;
        margin-bottom: 2rem;

        &:hover {
            text-decoration: none;
        }
    }
}