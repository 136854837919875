.pagination {
    .page-item {
        margin-right: 1.2rem;

        .page-link {
            border-radius: 0;
            padding: .5rem .85rem;
            background-color: #000;
            border-color: #000;
            color: #fff;
            
            &.current {
                background-color: #fff;
                border-color: #000;
                color: #000;
            }
        }
    }
}