// Bootstrap
.form-control {
    padding: .375rem .85rem;
    color: #7EC5FF;
    border: none;
    border-radius: $theme-radius;
    background-color: var(--theme-color-blue-light);
    
    &::placeholder {
        color: #7EC5FF;
    }
    
    &:focus {
        box-shadow: none;
        background-color: var(--theme-color-blue-light);
    }
}

// Wordpress
.alignleft, img.alignleft {
	margin-right: 1.5em;
    float: left;
    max-width: 100%;
    height: auto;
}

.alignright, img.alignright {
	margin-left: 1.5em;
    float: right;
    max-width: 100%;
    height: auto;
}

.aligncenter, img.aligncenter {
	margin-right: auto;
	margin-left: auto;
	display: block;
    clear: both;
    max-width: 100%;
    height: auto;
}

.alignnone, img.alignnone {
    max-width: 100%;
    height: auto;
}

// Contact Form
.wpcf7-not-valid-tip {
    font-size: 12px !important;
    text-align: center;
}

.wpcf7-response-output {
    font-size: 14px;
    text-align: center;
    border: 2px solid var(--theme-color-blue) !important;
    border-radius: $theme-radius;
    margin: 2em auto 1em !important;
    max-width: calc(100% - 1.5rem);

    form.sent & {
        border: 2px solid #0fb331 !important;
    }
}

.wpcf7-acceptance {
    .wpcf7-list-item {
        font-size: 14px;
        margin-left: 0 !important;

        input {
            width: 20px;
            height: 20px;
            position: relative;
            top: 3px;
            margin-right: .5rem;
        }
    }
}

.wpcf7-spinner {
    display: none !important;
}

.grecaptcha-badge {
    display: none !important;
}
