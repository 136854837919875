@font-face {
  font-family: "Soleil";
  src: url("./../fonts/soleil-light.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Soleil";
  src: url("./../fonts/soleil-regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Soleil";
  src: url("./../fonts/soleil-book.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Soleil";
  src: url("./../fonts/soleil-semibold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Soleil";
  src: url("./../fonts/soleil-bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
}

$font-prime: "Soleil", sans-serif;

html {
    --theme-color-black: #060606;
    --theme-color-blue: #0A21E8;
    --theme-color-blue-medium: #cce8ff;
    --theme-color-blue-light: #e6f4ff;
    --theme-color-azure: #008CFF;
    --theme-color-violet: #660DFF;
    --theme-color-aqua: #0CE8E8;
    --theme-color-green: #14FE8C;
    --theme-color-gray-70: #6F6F6F;
    --theme-color-gray-60: #878787;
    --theme-color-gray-20: #E5F3FF;
}

$theme-radius: 5px;

$headings-font-weight: 700;
$grid-gutter-width: 30px;

$enable-negative-margins: true;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3,
  7: $spacer * 4,
);

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
// $grid-columns: 12;
// $grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);
// $container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1140px);

// $gutter: $grid-gutter-width;
