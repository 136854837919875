.ms-offer {
    position: relative;
    padding: 60px 0;
    z-index: 12;
    
    @include media-breakpoint-up(lg){
        padding: 100px 0 50px;
    }

    &__header {
        color: var(--theme-color-blue);
        margin-bottom: 5rem;
        
        @include media-breakpoint-up(xl){
            margin-bottom: 6rem;
            font-size: 3.5rem;
        }

        &--medium {
            font-size: 1.75rem;
            font-weight: 400;
            color: var(--theme-color-azure);
        }
    }

    &__title {
        font-size: 2.125rem;
        color: var(--theme-color-blue);
        
        @include media-breakpoint-up(xl){
            font-size: 2.25rem;
        }
    }

    &__desc {
        font-size: 1.125rem;

        @include media-breakpoint-up(xl){
            font-size: 1.5rem;
            line-height: 1.2;
        }
    }

    &__top{
        position: absolute;
        top: -100px;
        width: 100%;
        height: 165px;
        background-image: url('./../img/svg/ms-bg.svg');
        background-position: center;
    }
}

.ms-software {
    position: relative;
    padding: 1rem 0 0;
    z-index: 5;
    margin-bottom: 3rem;
    
    @include media-breakpoint-up(xl){
        padding: 4rem 0 0;
    }

    &__wrapper {
        position: relative;
        padding: 5rem 0 0;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 580px;
            background-color: var(--theme-color-azure);
            transform: skewY(-1deg);
            z-index: -2;
        }
    }

    &__box {
        text-align: center;
        height: 100%;
        padding: 1.75rem .75rem;
        border-radius: $theme-radius;
        background-color: $white;
        box-shadow: 10px 20px 40px #0A21E841;

        .__icon {
            width: 75px;
            height: 75px;
            margin: 0 auto .75rem;

            img {
                max-width: 100%;
                height: auto;
            }
        }

        .__title {
            font-size: 1.75rem;

            @include media-breakpoint-up(xxl){
                font-size: 2rem;
            }
        }
    }
}

.ms-iframe {
    position: relative;
    padding-bottom: 8rem;
    margin-top: 2.5rem;

    @include media-breakpoint-up(sm){
        margin-top: 0;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(180deg,#ffffff 0,#E5F3FF 100%) 0 0 no-repeat padding-box;
        z-index: -1;
    }

    &__title {
        font-size: 3rem;

        @include media-breakpoint-up(xxl){
            &--small {
                font-size: 3.5rem;
            }
        }
    }

    &__video {
        overflow: hidden;
        border-radius: $theme-radius;
        border: 3px solid var(--theme-color-blue);
        margin: 3rem auto;
        max-width: 100%;
        height: 320px;
        box-shadow: 10px 20px 40px #0A21E841;
        
        @include media-breakpoint-up(md){
            height: 450px;
        }
        @include media-breakpoint-up(lg){
            height: 600px;
            max-width: 1050px;
        }
    }
}

.ms-classic {
    margin-top: 6rem;

    &__header {
        font-size: 2.25rem;
        color: var(--theme-color-azure);
        margin-bottom: 3rem;
    }

    &__box {
        margin-bottom: 4rem;
        
        @include media-breakpoint-up(sm){
            margin-bottom: 5rem;
        }
        
        .__box {
            font-size: 1.125rem;
            display: flex;
            gap: 2.125rem;
            
            div {
                text-align: left;

                &:first-child(){
                    width: 65px;
                    height: 65px;

                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
    
            span {
                font-size: 1.25rem;
                font-weight: 700;
                display: block;
                color: var(--theme-color-azure);
            }

            @include media-breakpoint-up(sm){
                font-size: 1.25rem;

                div {
                    &:first-child(){
                            width: 75px;
                        height: 75px;
                    }
                }

                span {
                    font-style: 1.5rem;
                }
            }
        }
    }
}