.footer {
    color: $white;
    padding: 4rem 0 0;
    background-color: var( --theme-color-blue);
    
    @include media-breakpoint-up(md){
        font-size: .875rem;
    }

    a {
        transition: color .3s ease-in-out;

        &:hover {
            color: var(--theme-color-green);
        }
    }

    ul {
        list-style: none;
        padding-left: 0;
        text-transform: uppercase;
        margin: 0;

        li {
            padding-bottom: 0.75rem;
        }

        ul {
            font-size: .75rem;
            text-transform: lowercase;
            padding-left: 1rem;
            margin-top: 0.85rem;
        }
    }

    &__title {
        font-size: 1.375rem;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 0.75rem;
    }

    &__socials {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: .85rem;
        margin-top: 1.5rem;

        @include media-breakpoint-down(md){
            justify-content: center;
            gap: 1.85rem;

            svg {
                transform: scale(1.5);
            }
        }

        a {
            path {
                transition: fill .3s cubic-bezier(0.39, 0.575, 0.565, 1);
            }

            &:hover {
                path {
                    fill: var(--theme-color-green);
                }
            }
        }
    }

    &__content {
        font-weight: 300;
        line-height: 1.8;

        a {
            font-weight: 500;
        }
    }

    &__buttons {
        display: flex;
        height: 100%;
        margin-top: 1.5rem;
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: center;
        
        .btn {
            font-size: 0.85rem;
            border: 2px solid $white;
            width: 175px;
            transition: transform .3s ease-in-out;

            &:hover {
                color: #fff;
                transform: scale(1.1);
            }
        }

        @include media-breakpoint-up(md){
            margin-top: 0;
            flex-direction: column;
            align-items: flex-end;
        }
    }

    &__logo {
        
        @include media-breakpoint-up(md){
            margin-top: 2rem;
        }

        img {
            width: 90vw;
            max-width: 300px;
            filter: brightness(0) invert(1);
            margin-bottom: 1rem;

            @include media-breakpoint-up(md){
                width: auto;
                max-width: 100%;
                height: 56px;
                margin-bottom: 0;
            }
            
            @include media-breakpoint-up(xl){
                margin-bottom: 1.5rem;
            }
        }
        
    }

    &__copy {
        color: var(--theme-color-black);
        padding: .5rem 1rem;
        text-align: center;
        font-size: .75rem;
        background-color: #fff;
        margin-top: 3em;
    }
}