.section {
    padding: 6rem 0 3rem;
    
    @include media-breakpoint-up(lg){
        padding: 4rem 0 3rem;
    }

    &__title {
        font-size: 2.5rem;
        font-weight: 700;
        color: $white;

        &--small {
            font-size: 2.5rem;
            line-height: 1.2;
        }

        @include media-breakpoint-up(sm){
            font-size: 3.375rem;

            &--medium {
                font-size: 3rem;
            }
        }

        @include media-breakpoint-up(xxl){
            font-size: 4.5rem;

            &--small {
                font-size: 3.5rem;
            }

            &--medium {
                font-size: 4rem;
            }
        }
    }

    &__header {
        font-size: 2rem;

        @include media-breakpoint-up(lg){
            font-size: 2.5rem;
        }
        @include media-breakpoint-up(xxl){
            font-size: 3.5rem;
        }
    }

    &__desc {
        font-size: 1.25rem;

        &--small {
            font-size: 1rem;
        }

        @include media-breakpoint-up(xl){
            font-size: 1.75rem;

            &--small {
                font-size: 1.25rem;
            }

            &--medium {
                font-size: 1.375rem;
                max-width: 500px;
            }
        }
    }
}