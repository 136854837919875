.home-service {
    position: relative;
    padding: 3rem 1rem 8rem;
    background-image: url('./../img/svg/service-bg.svg');
    background-repeat: no-repeat;
    background-position: center bottom;
    margin-top: -8rem;
    background-size: cover;
    z-index: 8;

    @include media-breakpoint-up(lg){
        padding: 0 0 7rem;
        margin-top: -10rem;
        background-size: unset;
    }

    &::before {
        content: '';
        position: absolute;
        width: 245px;
        height: 1400px;
        left: 0;
        right: 0;
        margin: auto;
        top: 1200px;
        background-image: url('./../img/svg/service-line-mobile.svg');
        background-repeat: no-repeat;
        z-index: -1;

        @include media-breakpoint-up(sm){
            top: 900px;
        }
        
        @include media-breakpoint-up(md){
            background-image: url('./../img/svg/service-line.svg');
            height: 790px;
        }
    }

    &__row {
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 5rem;
        
        @include media-breakpoint-up(sm){
            &:last-child {
                margin-bottom: 10rem;
            }
        }
        
        @include media-breakpoint-up(md){
            &:last-child {
                margin-bottom: 14rem;
            }
        }
        @include media-breakpoint-up(lg){
            &:last-child {
                margin-bottom: 10rem;
            }
        }
        @include media-breakpoint-up(xxl){
            &:last-child {
                margin-bottom: 5rem;
            }
        }
    }

    &__title {
        margin: 4.5rem 0;

        @include media-breakpoint-down(sm){
            font-size: 4.5rem;
            line-height: 1;
        }
        @include media-breakpoint-up(lg){
            margin: 12rem 0 4rem;
        }
    }

    &__slider {
        .slick-track {
            display: flex;

            .slick-slide {
                height: inherit;

                > div {
                    height: 100%;
                    padding: 1.25rem .75rem;

                    > div {
                        height: 100%;
                        
                        .it-box {
                            box-shadow: 0px 4px 15px #0a21e82b;
                        }
                    }
                }
            }
        }

        .slick-dots {
            position: relative;
            display: flex;
            justify-content: center;
            gap: .75rem;
            margin: 2rem auto 0;
            padding: 0;
            list-style: none;
            z-index: 9999;

            .dot {
                cursor: pointer;
                width: 30px;
                height: 4px;
                border-radius: 2px;
                background-color: var(--theme-color-gray-70);
                transition: background-color .25s ease-in-out;
            }

            .slick-active {
                .dot {
                    background-color: var(--theme-color-blue);
                }
            }
        }
    }
}

.home-blog {
    position: relative;
    padding: 3rem 0 2rem;
    overflow: hidden;
    background-image: url('./../img/svg/arrows-bg.svg');
    background-repeat: no-repeat;
    background-size: 200px 380px;
    background-position: left -60px;
    
    @include media-breakpoint-up(lg){
        background-position: calc((100vw - 1320px)/2 - 160px) 8%;
        background-size: unset;
        padding: 4rem 0 2rem;
    }
    @include media-breakpoint-up(xxl){
        padding: 8rem 0 2rem;
    }

    &::before {
        content: '';
        background-image: url('./../img/svg/arrows-bg.svg');
        background-size: cover;
        position: absolute;
        width: 200px;
        height: 380px;
        bottom: 0;
        right: -2rem;
        z-index: -1;
        
        @include media-breakpoint-up(lg){
            background-image: none;
            content: url('./../img/svg/arrows-bg.svg');
            top: 36%;
            bottom: auto;
            width: unset;
            height: unset;
            right: calc((100vw - 1320px)/2 - 130px);
        }
    }

    &--bg {
        position: relative;
        margin: 5rem 0 1rem;
        
        @include media-breakpoint-up(sm){
            margin: 5rem 0 3rem;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: auto;
            background-color: var(--theme-color-azure);
            width: calc(100vw - 2rem);
            height: 620px;
            opacity: .2;
            border-radius: $theme-radius;
            z-index: -2;

            @include media-breakpoint-up(md){
                width: calc(100vw - 6rem);
            }
            @include media-breakpoint-up(xl){
                width: 85vw;
            }
            @include media-breakpoint-up(xxl){
                top: 50px;
            }
        }

        &--green {
            &::after {
                background-color: var(--theme-color-green);
            }
        }
    }

    &__wrapper {
        margin: 3rem auto 0;

        .slick-dots {
            position: relative;
            display: flex;
            justify-content: center;
            gap: .75rem;
            margin: -1rem auto 0;
            padding: 0;
            list-style: none;
            z-index: 9999;

            .dot {
                cursor: pointer;
                width: 30px;
                height: 4px;
                border-radius: 2px;
                background-color: var(--theme-color-gray-70);
                transition: background-color .25s ease-in-out;
            }

            .slick-active {
                .dot {
                    background-color: var(--theme-color-blue);
                }
            }
        }
        
        @include media-breakpoint-up(sm){
            margin: 3rem auto;
        }
        @include media-breakpoint-up(lg){
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 2rem;
            padding: 0 2rem;
            max-width: 1056px;
            margin: 6rem auto;
        }
        @include media-breakpoint-up(xxl){
            margin: 6rem auto 8rem;
        }
    }
}

.home-technology {
    position: relative;
    padding: 0 0 4rem;
    overflow: hidden;
    z-index: 5;
    
    @include media-breakpoint-up(lg){
        padding: 2rem 0 4rem;
    }

    &__wrapper {
        position: relative;
        padding: 2rem 0 4rem;

        &::before {
            content: '';
            position: absolute;
            left: -5%;
            width: 110%;
            height: 100%;
            background-color: #660dff;
            transform: rotate(-1deg);
            z-index: -2;
        }
    }

    &__title {
        font-size: 2.5rem;
        font-weight: 700;
        color: $white;
        line-height: 1.2;

        @include media-breakpoint-up(sm){
            font-size: 2.75rem;
        }
        @include media-breakpoint-up(xxl){
            font-size: 3.125rem;
        }
    }

    &__box {
        display: flex;
        flex-wrap: wrap;
        max-width: calc(100% - 2rem);
        justify-content: center;
        gap: 1.5rem;
        margin: -2.5rem auto 0;
        padding: 1.25rem 3rem 2rem;
        background-color: $white;
        border-radius: $theme-radius;
        box-shadow: 10px 20px 40px #0A21E841;
        
        @include media-breakpoint-up(sm){
            max-width: calc(100% - 4rem);
        }
        @include media-breakpoint-up(lg){
            justify-content: space-between;
        }
        @include media-breakpoint-up(xl){
            max-width: 1050px;
        }

        h4 {
            text-align: center;
        }

        img {
            max-width: 100%;
            height: auto;
            max-height: 65px;
            
            @include media-breakpoint-up(md){
                max-width: 190px;
            }
        }
    }
}

.home-solutions {
    position: relative;
    padding: 2rem 0 4rem;
    
    @include media-breakpoint-up(sm){
        padding: 4rem 0;
    }

    &::before {
        content: '';
        position: absolute;
        top: -12rem;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg,#ffffff 0,#E5F3FF 100%) 0 0 no-repeat padding-box;
        z-index: -1;
    }

    &__title {
        font-size: 2.5rem;

        @include media-breakpoint-up(lg){
            font-size: 3.125rem;
        }
    }
}

.home-help {
    &__box {
        display: flex;
        flex-direction: column-reverse;
        margin: 1rem 0 4rem;
        background-color: var(--theme-color-blue-medium);
        border-radius: $theme-radius;
        overflow: hidden;
        
        @include media-breakpoint-up(sm){
            margin: 3rem 0 6rem;
        }
        @include media-breakpoint-up(md){
            display: grid;
            grid-template-columns: 60% 40%;
            max-height: 460px;
        }
    }

    &__content {
        display: grid;
        place-content: center;
        text-align: center;
        padding: 2rem 1rem;
        
        @include media-breakpoint-up(md){
            text-align: left;
            padding: 2rem 3.5rem;
        }
    }

    &__title {
        font-size: 2rem;
        
        @include media-breakpoint-up(sm){
            font-size: 2.5rem;
        }
        @include media-breakpoint-up(lg){
            font-size: 3.125rem;
        }
    }

    &__img {
        height: 280px;
        
        @include media-breakpoint-up(md){
            height: auto;
            max-height: 460px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

.home-desk {
    position: relative;
    padding: 4rem 0;
    overflow: hidden;
    
    @include media-breakpoint-up(lg){
        padding: 7rem 0;
    }
    @include media-breakpoint-up(xl){
        padding: 9rem 0;
    }
}