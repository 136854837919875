.offer-card {
    height: 100%;
    padding: 2rem 1.5rem;
    margin: auto;
    border-radius: $theme-radius;
    background-color: $white;
    max-width: 420px;
    box-shadow: 10px 20px 40px #0A21E841;
    
    @include media-breakpoint-up(lg){
        padding: 2rem;
    }

    &__header {
        display: flex;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        color: var(--theme-color-blue);
        margin-bottom: 1rem;
    }

    &__body {
        text-align: center;
    }

    &__row {
        position: relative;
        margin-top: 3rem;
        z-index: 4;

        &::before {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 200px;
            height: calc(100% - 4rem);
            margin: auto;
            background-image: url('./../img/svg/offer-line.svg');
            background-repeat: no-repeat;
            z-index: -1;
        }
        
        @include media-breakpoint-up(sm){
            margin-top: 6rem;

            &::before {
                display: none;
            }
        }
        @include media-breakpoint-up(xxl){
            margin-top: 8rem;
        }
    }
}

.offer-product {
    margin-bottom: 3rem;

    @include media-breakpoint-up(lg){
        margin-bottom: 6rem;
    }
}

.offer-proces {
    margin-top: 4rem;
    padding: 8rem 0;
    background-image: url('./../img/svg/graph-bg.svg');
    background-repeat: no-repeat;
    background-position: center top;
    
    @include media-breakpoint-up(sm){
        padding: 10rem 0 12rem;
    }
    @include media-breakpoint-up(lg){
        padding: 12rem 0 16rem;
    }

    &__img {
        width: 85%;
        max-width: 100%;
        height: auto;
        
        @include media-breakpoint-up(sm){
            width: 70%;
        }
    }
}