.blog-header {
    margin: auto;
    max-width: 1920px;

    h2 {
        text-shadow: 0px 0px 10px #0A21E880;
    }

    .section__title {
        @include media-breakpoint-down(md){
            font-size: 2rem;
        }
    }

    &__wrapper {
        position: relative;
        padding: 6rem 0 8rem;
        height: 500px;
        overflow: hidden;
        z-index: 5;
        
        @include media-breakpoint-up(sm){
            height: 680px;
            padding: 4rem 0 8rem;
        }

         &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: var(--theme-color-blue);
            opacity: 0.65;
            z-index: -1;
        }
    }

    &__pretitle {
        font-size: 1.25rem;
        text-transform: uppercase;
        display: inline-block;
        color: var(--theme-color-blue);
        padding: .15rem 1rem;
        background-color: #fff;
        margin-bottom: 2rem;
        text-shadow: none !important;

        @include media-breakpoint-up(md){
            font-size: 1.5rem;
        }
    }

    &__title {
        font-size: 0;
        opacity: 0;
    }

    &__desc {
        display: none;
        font-size: 1.25rem;
        max-width: 800px;
        margin: 1rem auto 1.75rem;
        color: #fff;
        text-shadow: 0px 0px 5px #00000065;

        @include media-breakpoint-up(sm){
            display: block;
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -2;

        img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }
}

.blog-recent {
    position: relative;
    margin-top: -6rem;
    z-index: 5;
}

.blog-posts {
    margin: 3rem 0;
    
    @include media-breakpoint-up(lg){
        margin: 9rem 0 6rem;
    }

    &__title {
        margin-bottom: 2.5rem;
        
        @include media-breakpoint-up(sm){
            margin-bottom: 5rem;
        }
    }
}

.blog-join {
    position: relative;
    padding: 6rem 0 5rem;
    overflow: hidden;
    
    @include media-breakpoint-up(sm){
        padding: 4rem 0 5rem;
    }
    @include media-breakpoint-up(xl){
        padding: 9rem 0 6rem;
    }

    &__title {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 1rem;
        color: var(--theme-color-blue);
        
        @include media-breakpoint-up(sm){
            font-size: 2.5rem;
        }
        @include media-breakpoint-up(xl){
            font-size: 3rem;
        }
    }

    &__desc {
        @include media-breakpoint-up(xl){
            font-size: 1.25rem;
            line-height: 1.3;
            max-width: 500px;
        }
    }
}

.blog-newsletter {
    padding: 5rem 0;
    background-image: linear-gradient(28deg, #008CFF, #0CE8E8);

    &--page {
        border-radius: $theme-radius;

        @include media-breakpoint-down(md){
            padding: 3rem 0;
        }
    }

    &__pretitle {
        font-size: 1.5rem;
        color: var(--theme-color-blue);
    }

    &__title {
        font-size: 2.5rem;
        
        @include media-breakpoint-up(xl){
            font-size: 3.5rem;
        }
    }
}

.mc4wp-form {
    .mc-form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        padding: 1rem 0;
    
        @include media-breakpoint-up(sm){
            flex-direction: row;
        }
    
        input {
            &[type=email]{
                min-height: 42px;
                background-color: $white;
            }
        }
    }
    
    .mc4wp-response {
        .mc4wp-alert {
            color: $white;
        }
    }
}