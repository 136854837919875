.subpage {
    .accordion {
        border: 0;
    
        &__item {
            &:nth-child(odd){
                background-color: var(--theme-color-gray-20);
            }
            .accordion-button {
                font-size: .875rem;
                padding: 1rem .75rem;
                background-color: transparent;
                transition: color .25s ease-in-out;
                color: var(--theme-color-black);
                box-shadow: none;
    
                @include media-breakpoint-up(sm){
                    font-size: 1rem;
                }
    
                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82 9L16 18.2713L25.18 9L28 11.8543L16 24L4 11.8542L6.82 9Z' fill='%23333333'/%3E%3C/svg%3E%0A"); 
                }
                
                &:not(.collapsed){
                    color: var(--theme-color-blue);
                    
                    &::after {
                        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.82 9L16 18.2713L25.18 9L28 11.8543L16 24L4 11.8542L6.82 9Z' fill='%230A21E8'/%3E%3C/svg%3E%0A"); 
                    }
                }
    
                &:hover,
                &:focus {
                    color: var(--theme-color-blue);
                    box-shadow: none;
                }
            }
    
            .accordion-body {
                padding: .5rem .75rem 1rem;
    
                &--files {
                    display: flex;
                    flex-direction: column;
                    gap: .35rem 0;
                }
            }
        }
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 1.5rem;
    }

    a:not(.btn) {
        color: var(--theme-color-blue);

        &:hover {
            color: var(--theme-color-black);
        }
    }

    ul {
		line-height: 1.4;
		padding-left: 1rem;
		
		li {
			list-style-image: url("data:image/svg+xml,%3Csvg width='9' height='14' viewBox='0 0 9 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.65039 3.80005L7.98372 10.1334L1.65039 16.4667' stroke='%230A21E8' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
			padding-bottom: .6rem;
			padding-left: .125rem;

			a {
				color: var(--theme-color-black);
				transition: color .25s ease-in-out;

				&.active,
				&:hover {
					color: var(--theme-color-blue);
				}
			}
		}
	}
}

.page-template-page-flexible-content {
    h1 {
        @extend .section__header;
    }
}