.hero {
    position: relative;
    margin: .75rem;
    background-color: var(--theme-color-blue-medium);
    border-radius: $theme-radius;
    overflow: hidden;
    
    @include media-breakpoint-up(lg){
        margin: 1.5rem 1rem;
    }

    &__container {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        
        @include media-breakpoint-up(md){
            display: grid;
            height: 550px;
            grid-template-columns: 60% 40%;
        }
        @include media-breakpoint-up(lg){
            height: 730px;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: 3rem 1rem 7rem;
        margin: 0 auto;
        max-width: 700px;
        
        @include media-breakpoint-up(sm){
            padding: 3rem 2rem 7rem;
        }
        @include media-breakpoint-up(md){
            padding: 8rem 2rem 2rem;
        }
        @include media-breakpoint-up(xl){
            padding: 8rem 5rem 2rem;
        }
    }

     &__title {
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        margin-bottom: 1rem;
        color: var(--theme-color-blue);
        
        @include media-breakpoint-up(sm){
            font-size: 2.5rem;
            margin-bottom: 2rem;
            text-align: left;
        }
        @include media-breakpoint-up(lg){
            font-size: 3.85rem;
            line-height: 1;
        }
    }

    &__desc {
        font-size: 1.125rem;
        text-align: center;
        line-height: 1.3;

        @include media-breakpoint-up(sm){
            text-align: left;
        }
        
        @include media-breakpoint-up(xxl){
            font-size: 1.375rem;
        }
    }

    &__img {
        height: 400px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        @include media-breakpoint-up(md){
            height: 100%;
        }
    }
}