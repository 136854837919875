.cta {
    padding: 6rem 0 4rem;
    overflow: hidden;
    background-image: url('./../img/svg/cta-bg.svg');
    background-repeat: no-repeat;
    background-position: center top;

    &--dark {
       background-image: url('./../img/svg/cta-bg-dark.svg'); 
    }

    &--ms {
        margin-top: 8rem;
    }
    
    @include media-breakpoint-up(xl){
        padding: 10rem 0 8rem;
    }
    
    &__img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
    }

    .btn {
        box-shadow: 0px 15px 50px #660DFF83;
    }
}