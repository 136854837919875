.text-blue {
    color: var(--theme-color-blue) !important;
}

.text-azure{
    color: var(--theme-color-azure) !important;
}

.text-green {
    color: var(--theme-color-green) !important;
}

.underline-green {
    text-decoration: underline;
    text-decoration-color: var(--theme-color-green);
}