.desk-profits {
    padding: 5rem 0;
    
    @include media-breakpoint-up(sm){
        padding: 6rem 0 10rem;
    }

    &--gradient {
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 40%;
            background: linear-gradient(180deg,#ffffff 0,#E5F3FF 100%) 0 0 no-repeat padding-box;
            z-index: -2;
        }
    }

    &__header {
        color: var(--theme-color-blue);
        margin-bottom: 8rem;

        &--medium {
            font-size: 1.75rem;
        }
    }

    &__title {
        font-size: 2.125rem;
        color: var(--theme-color-blue);
        
        @include media-breakpoint-up(xl){
            font-size: 3.75rem;
        }
    }

    &__desc {
        font-size: 1.125rem;

        @include media-breakpoint-up(xl){
            font-size: 1.5rem;
            line-height: 1.2;
        }
    }

    &__row {
        position: relative;
        margin-bottom: 5rem;

        @include media-breakpoint-down(sm){
            img {
                max-width: 65%;
            }
        }
    }

    &__number {
        position: absolute;
        left: calc(50% - 160px);
        top: -10px;
        z-index: 0;

        @include media-breakpoint-down(sm){
            img {
                max-height: 200px;
            }
        }
        
        @include media-breakpoint-up(lg){
            right: -35px;
            left: auto;
            z-index: -1;
        }
    }
}