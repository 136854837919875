.home-technology__box--center {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: -80px;
    justify-content: center;
    z-index: 999;

    @include media-breakpoint-down(sm){
        padding: 1.25rem 1.5rem 2rem;

        .d-flex {
            flex-wrap: wrap;
            justify-content: center;

            > div {
                width: 26%;
                text-align: center;

                &:nth-child(1),
                &:nth-child(2){
                    width: 45%;
                }
            }
        }
    }
}

.program-quality {
    position: relative;
    background-color: var(--theme-color-azure);
    padding: 8rem 0 1rem;
    margin-bottom: 4rem;
    z-index: 5;
    
    @include media-breakpoint-up(sm){
        padding: 11rem 0 6rem;
    }
    
    @include media-breakpoint-up(xl){
        padding: 12rem 0 6rem;
    }

    &__wrapper {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            bottom: -70px;
            left: 0;
            width: 100%;
            height: 250px;
            background-color: var(--theme-color-azure);
            transform: skewY(-1deg);
            z-index: -2;

            @include media-breakpoint-up(sm){
                bottom: -120px;
            }
        }
    }
}

.program-software {
    position: relative;
    padding: 1rem 0 0;
    z-index: 5;
    
    @include media-breakpoint-up(xl){
        padding: 4rem 0 0;
    }

    &__wrapper {
        position: relative;
        padding: 5rem 0 0;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 550px;
            background-color: var(--theme-color-violet);
            transform: skewY(-1deg);
            z-index: -2;
        }
    }

    &__box {
        margin: 5rem auto 0;
        max-width: 700px;
        padding: 2rem 2.5rem 1.5rem;
        text-align: center;
        background-color: $white;
        border-radius: $theme-radius;
        box-shadow: 10px 20px 40px #0A21E841;

        &--wide {
            max-width: 100%;
            display: grid;
            grid-template-columns: 1fr;
        }
        
        @include media-breakpoint-up(sm){
            padding: 3rem 14vw 1.5rem;
        }
        @include media-breakpoint-up(lg){
            padding: 3rem 5rem 1.5rem;

            &--wide {
                grid-template-columns: 1fr 1fr;
                padding-bottom: 0 !important;
            }
        }
        // @include media-breakpoint-up(xl){
        //     padding: 3rem 5rem 1.5rem;
        // }
        
        .__header {
            font-size: 2.25rem;
            font-weight: 700;
            color: var(--theme-color-blue);
            margin-bottom: 3rem;
            
            @include media-breakpoint-up(sm){
                font-size: 3rem;
            }
            @include media-breakpoint-up(xl){
                font-size: 4rem;
            }
        }

        .__item {
            margin-bottom: 4rem;
            
            @include media-breakpoint-up(sm){
                margin-bottom: 5rem;
            }
            
            .__box {
                font-size: 1.125rem;
                display: flex;
                gap: 2.125rem;
                
                div {
                    text-align: left;

                    &:first-child(){
                        width: 65px;
                        height: 65px;
    
                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
        
                span {
                    font-size: 1.25rem;
                    font-weight: 700;
                    display: block;
                    color: var(--theme-color-azure);
                }

                @include media-breakpoint-up(sm){
                    font-size: 1.25rem;

                    div {
                        &:first-child(){
                             width: 75px;
                            height: 75px;
                        }
                    }

                    span {
                        font-style: 1.5rem;
                    }
                }
            }
        }
    }

    &--ms {
        margin-top: -5rem;

        .program-software__box--wide {
            @include media-breakpoint-only(md){
                max-width: 700px;
            }
        }
    }
}