.introbar {
    position: relative;
    width: 100%;
    height: 700px;

    &--ms {
        @include media-breakpoint-down(sm){
            .introbar__img img {
                object-position: 10% 0%;
            }
        }
        @include media-breakpoint-down(xl){
            height: 600px;
        }

        @include media-breakpoint-only(sm){
            .introbar__content {
                padding: 7rem 0 4rem !important;
            }
        }
    }

    &__img {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;

        img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }

    &__pretitle {
        text-transform: uppercase;
        line-height: 1;
        color: var(--theme-color-blue);
    }

    &__title {
        font-size: 2.75rem;
        line-height: 1.2;
        color: $white;
        text-shadow: 0px 5px 10px #00000029;
        
        @include media-breakpoint-up(sm){
            font-size: 4.25rem;
        }
        @include media-breakpoint-up(xl){
            font-size: 5.5rem;
        }
    }
    
    &__desc {
        font-size: 1.5rem;
        margin: 1rem 0 1.5rem;
        line-height: 1.2;
        color: $white;
        
        @include media-breakpoint-up(xl){
            font-size: 2.5rem;
        }
    }

    &__content {
        text-align: center;
        padding: 10rem 0 4rem;
        
        @include media-breakpoint-up(md){
            padding: 8rem 0 4rem;
            text-align: left;
        }
    }

    &__boxes {
        margin-top: -10rem;

        .it-box {
            padding: 2rem 1rem 1rem;

            @include media-breakpoint-up(lg){
                padding: 2rem .75rem 0rem;
            }
        }

        .it-box__icon {
            width: 54px;
            height: 54px;
            
            @include media-breakpoint-up(xl){
                width: 74px;
                height: 74px;
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        .it-box__title {
            font-size: 1.25rem;
            
            @include media-breakpoint-up(xl){
                font-size: 1.75rem;
            }
        }
    }

    &__icons {
        display: flex;
        gap: 1.2rem;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 2rem;
        
        @include media-breakpoint-up(lg){
            justify-content: end;
        }

        div {
            display: grid;
            place-content: center;
            width: 45px;
            height: 45px;
            padding: .75rem;
            background-color: $white;
            border-radius: 5rem;
            
            @include media-breakpoint-up(sm){
                width: 60px;
                height: 60px;
            }

            img {
                max-width: 100%;
                height: auto;
            }

            span {
                font-size: 2.5rem;
                margin-top: -4px;
                line-height: 1;
                font-weight: 400;
                color: var(--theme-color-blue);
            }

            &.__plus {
                a {
                    width: 100%;
                    height: 100%;
                    margin-top: -4px;
                }
            }
        }
    }
}