.admin-profits {
    padding: 5rem 0 0;
    
    @include media-breakpoint-up(sm){
        padding: 6rem 0 0;
    }
    @include media-breakpoint-up(lg){
        padding: 6rem 0 3rem;
    }

    &__row {
        position: relative;
        margin-bottom: 5rem;
        max-width: calc(100% - 2rem);
        margin-left: auto;
        margin-right: auto;

        &::before {
            content: '';
            position: absolute;
            width: calc(100% + 1.5rem);
            height: calc(100% + 3rem);
            top: -1.5rem;
            left: -.75rem;
            border: 3px solid var(--theme-color-azure);
            border-radius: $theme-radius;
            z-index: -1;
        }

        .desk-profits__title {
            @include media-breakpoint-up(xl){
                font-size: 3.125rem;
            }
        }

        @include media-breakpoint-up(md){
            max-width: 600px;

            &::before {
                width: calc(100% + 3rem);
                left: -1.5rem;
            }
        }
        @include media-breakpoint-up(lg){
            max-width: initial;

            &::before {
                display: none;
            }
        }
    }

    &__header {
        color: var(--theme-color-blue);
        margin-bottom: 5rem;
        
        @include media-breakpoint-up(xl){
            margin-bottom: 8rem;
        }

        &--medium {
            font-size: 1.75rem;
        }
    }
}

.admin-steps {
    position: relative;
    overflow: hidden;
    padding: 10rem 0 3rem;
    margin-bottom: 1rem;
    background-image: url("../img/svg/work-bg-2.svg");
    background-repeat: no-repeat;
    background-position: center top;
    color: $white;
    z-index: 9;
    background-size: cover;

    @include media-breakpoint-up(md){
        padding: 10rem 0 8rem;
        margin-bottom: 8rem;
    }
    
    h3 {
        color: $white;
    }

    &__row {
        position: relative;
        margin-bottom: 5rem;
        
        @include media-breakpoint-up(sm){
            margin-bottom: 8rem;
        }
    }

    &__col {
        text-align: center;
        position: relative;

        @include media-breakpoint-up(lg){
            text-align: left;
        }
    }

    
    &__header {
        color: var(--theme-color-blue);
        margin-bottom: 4rem;
        
        @include media-breakpoint-up(lg){
            margin-bottom: 8rem;
        }
    }


    &__number {
        position: absolute;
        left: 0;
        top: -40px;
        z-index: 0;

        img {
            max-height: 220px;

            @include media-breakpoint-up(xl){
                max-height: initial;
            }
        }
        
        @include media-breakpoint-up(lg){
            left: -55px;
            z-index: -1;
        }
    }
}