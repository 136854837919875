.it-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 2rem 1rem;
    text-align: center;
    background-color: $white;
    border-radius: $theme-radius;
    box-shadow: 10px 20px 40px #0A21E841;
    transition: box-shadow .35s cubic-bezier(0.39, 0.575, 0.565, 1);

    &:hover {
       box-shadow: 10px 20px 20px -10px #0A21E841; 
    }

    &__icon {
        margin: auto;
        // width: 4rem;
        max-width: 6rem;
        height: 4rem;
        margin-bottom: 1.5rem;

        img {
            margin: auto;
        }
    }

    &__title {
        font-size: 1.5rem;
        font-weight: 700;

        @include media-breakpoint-up(xxl){
            font-size: 2rem;
        }
    }

    &__desc {
        font-size: 1rem;
        line-height: 1.3;
        color: var(--theme-color-gray-60);
        margin: 1rem 0;
        
        @include media-breakpoint-up(xl){
            font-size: 1.125rem;

            &--small {
                font-size: 1rem;
            }
        }
    }
}