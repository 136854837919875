html {
	scroll-behavior: smooth;
}

body {
	position: relative;
	font-size: 1em;
	color: var(--theme-color-black);
	height: 100%;
	line-height: 1.5;
	font-family: $font-prime;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@include media-breakpoint-up(lg){
	main {
		padding-top: 60px;
	}
}


@include media-breakpoint-between(sm,xxl){
	.container-xxl {
		padding-left: 2rem;
		padding-right: 2rem;
	}
}