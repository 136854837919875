.blog-card {
    height: 100%;
    margin: auto;
    max-width: 330px;
    border-radius: $theme-radius;
    box-shadow: 10px 20px 40px #0A21E841;
    background-color: $white;
    transition: box-shadow .35s cubic-bezier(0.39, 0.575, 0.565, 1);
    
    &:hover {
        box-shadow: 10px 20px 20px #0a20e81e;
    }

    &--slide {
        @include media-breakpoint-down(lg){
            margin: 1rem 1rem 5rem;
        }
    }

    &__thumb {
        position: relative;
        display: block;
        overflow: hidden;
        height: 220px;
        border-radius: $theme-radius $theme-radius 0 0;
        margin-bottom: 1.5rem;
        z-index: 5;

        // &::before {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background-color: var(--theme-color-blue);
        //     opacity: 0.65;
        //     z-index: 0;
        // }
        
        img {
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
        }
    }

    &__title {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        padding: 1rem;
        text-align: center;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        z-index: 0;
        
        h2, h3 {
            color: $white;
            font-size: 1.5rem;
            text-shadow: 0px 0px 6px #0A21E898;
            
            @include media-breakpoint-up(sm){
                font-size: 1.75rem;
            }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 3px;
        height: 100%;
    }

    &__body {
        font-size: 1.125rem;
        line-height: 1.2;
        color: var(--theme-color-gray-70);
        padding: 0 1.5rem;
        text-align: center;
        margin-bottom: 1.5rem;
    }

    &__btn {
        text-align: center;
        margin-bottom: 1.5rem;
    }

    &--small {
        border: 3px solid var(--theme-color-blue);

        .blog-card {
            &__title {
                h2, h3 {
                    font-size: 1.25rem;
                }
            }

            &__content {
                padding: 0;
            }

            &__thumb {
                height: 180px;
                border-radius: 0;
            }
            
            &__body {
                font-size: 1rem;
                padding: 0 .75rem;
                margin-bottom: 1rem;
            }

            &__btn {
                a {
                    font-size: 1rem;

                    &::after {
                        content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.215 0L6.767 1.448L12.497 7.188H0V9.242H12.5L6.77 14.982L8.218 16.43L16.433 8.215L8.215 0Z' fill='%230A21E8'/%3E%3C/svg%3E%0A");
                        height: 19px;
                    }
                }
            }
        }
    }
}