.intro {
    // padding: 3rem 0;
    padding: 6rem 0 3rem;
    overflow: hidden;
    
    // @include media-breakpoint-up(lg){
    //     padding: 6rem 0 3rem;
    // }

    &--decor {
        position: relative;

        &::before {
            content: '';
            display: none;
            position: absolute;
            width: 270px;
            height: 340px;
            background-image: url('./../img/svg/arrows-bg.svg');
            background-repeat: no-repeat;
            background-size: cover;
            top: 390px;
            left: calc((100% - 1320px)/2 - 80px);
            z-index: -1;

            @include media-breakpoint-up(lg){
                display: block;
            }
        }
    }

    &--blue {
        padding: 6rem 0 3rem;
        background-image: url('./../img/svg/intro-blue.svg');
        background-repeat: no-repeat;
        background-position: center top;
        
        @include media-breakpoint-up(lg){
            padding: 6rem 0;
            background-position: center 170%;
        }
    }

    &__pretitle {
        text-transform: uppercase;
        color: var(--theme-color-azure);
        
        &--blue {
            color: var(--theme-color-blue);
        }
    }

    &__desc {
        font-size: 1.5rem;
        line-height: 1.2;
        
        @include media-breakpoint-up(sm){
            font-size: 1.75rem;
        }
        @include media-breakpoint-up(xxl){
            font-size: 2.5rem;
        }
    }

    &__box {
        max-width: calc(100vw - 3rem);
        margin: 3.5rem 0 1rem;
        border: 3px solid var(--theme-color-blue);
        border-radius: $theme-radius;
        text-align: center;

        @include media-breakpoint-down(lg){
            box-shadow: 0px 30px 30px #0A21E841;

            .service-box {
                box-shadow: none;
            }
        }

        @include media-breakpoint-up(lg){
            margin: 6rem 0;
            text-align: left;
            border: none;
            border-radius: none;
        }
    }
}

.work-better {
    position: relative;
    padding: 8rem 0 2rem;
    background-image: url('./../img/svg/work-bg.svg');
    background-repeat: no-repeat;
    background-position: center top;
    
    @include media-breakpoint-up(sm){
        padding: 12rem 0 5rem;
    }

    &__row {
        margin: 2rem 0 4rem;
        
        @include media-breakpoint-up(sm){
            margin: 6rem 0;
        }
    }

    &__item {
        display: grid;
        gap: 1rem;
        margin-top: 3rem;
        line-height: 1.2;
        font-size: 1.25rem;
        font-weight: 700;
        grid-template-columns: 70px auto;

        img {
            max-width: 70px;
        }
        
        @include media-breakpoint-up(sm){
            grid-template-columns: 120px auto;

            img {
                max-width: initial;
            }
        }
        @include media-breakpoint-up(xl){
            font-size: 1.75rem;
        }
        @include media-breakpoint-up(xxl){
            font-size: 2rem;
            padding-right: 2rem;
        }

        span {
            display: block;
            font-size: 1.125rem;
            margin: 0.5rem 0 1rem;
            line-height: 1.4;
            font-weight: 400;
        }
    }
}

.work-join {
    position: relative;
    padding: 6rem 0 5rem;
    overflow: hidden;
    
    @include media-breakpoint-up(sm){
        padding: 4rem 0 5rem;
    }
    @include media-breakpoint-up(xl){
        padding: 4rem 0 8rem;
    }

    &::before {
        content: '';
        position: absolute;
        width: 170px;
        height: 210px;
        background-image: url('./../img/svg/arrows-bg.svg');
        background-repeat: no-repeat;
        background-size: cover;
        bottom: 80px;
        left: 0;
        z-index: -1;
        
        @include media-breakpoint-up(lg){
            top: 120px;
            bottom: auto;
            left: calc((100% - 1140px)/2 - 40px);
        }
        @include media-breakpoint-up(xl){
            width: 270px;
            height: 340px;
            top: 100px;
            left: calc((100% - 1320px)/2 - 80px);
        }
    }

    &::after {
        content: '';
        position: absolute;
        width: 170px;
        height: 210px;
        background-image: url('./../img/svg/arrows-bg.svg');
        background-repeat: no-repeat;
        background-size: cover;
        top: 0;
        right: -36px;
        z-index: -1;

        @include media-breakpoint-up(lg){
            display: none;
        }
    }

    &__title {
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 0.75rem;
        color: var(--theme-color-blue);
        
        @include media-breakpoint-up(sm){
            font-size: 2.5rem;
        }
        @include media-breakpoint-up(xl){
            font-size: 3rem;
        }
    }

    &__desc {
        @include media-breakpoint-up(xl){
            font-size: 1.5rem;
        }
    }
}

.work-quality {
    position: relative;
    overflow: hidden;
    padding: 6rem 0 12rem;
    background-image: url('./../img/svg/work-bg-2.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    
    @include media-breakpoint-up(sm){
        padding: 9rem 0 15rem;   
    }

    &__row {
        display: grid;
        grid-template-columns: 60px auto;
        color: $white;

        &:not(:last-child()){
            .work-quality__body{
                padding-bottom: 1.5rem;
            }
        }

        @include media-breakpoint-up(sm){
            grid-template-columns: 150px auto;
        }
    }
    
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 1rem 0 0;
        width: 60px;
        height: 40px;
        
        @include media-breakpoint-up(sm){
            padding: 1rem 1.5rem;
            width: 150px;
            height: 100px;
        }
    }
    
    &__body {
        border-left: 4px solid $white;
        padding-left: 1.25rem;
        
        @include media-breakpoint-up(sm){
            padding-left: 2rem;
        }
    }

    &__title {
        font-size: 1.75rem;

        @include media-breakpoint-up(lg){
            font-size: 2.5rem;
        }
        @include media-breakpoint-up(xl){
            font-size: 3rem;

            &--small {
                font-size: 2.5rem;
            }
        }
    }

    &__desc {
        line-height: 1.3;

        @include media-breakpoint-up(lg){
            font-size: 1.5rem;
        }
    }
}

.work-cta {
    position: relative;
    z-index: 8;
    margin-top: -8rem;
}

.sticky-rec-img {
    @include media-breakpoint-up(xl){
        position: sticky;
        top: 5rem;
    }
}