.contact-intro {
    position: relative;
    overflow: hidden;
    padding: 6rem 0 0;
    z-index: 5;
    
    @include media-breakpoint-up(sm){
        padding: 4rem 0 0;
    }

    &::before {
        content: '';
        position: absolute;
        background-image: url('./../img/svg/arrows-bg.svg');
        background-repeat: no-repeat;
        top: 8rem;
        width: 250px;
        height: 400px;
        left: -50px;
        z-index: -1;
        
        @include media-breakpoint-up(md){
            top: 5rem;
            width: 500px;
            height: 620px;
            left: calc((100% - 1320px)/ 2 - 10px);
        }
    }

    img {
        border-radius: $theme-radius $theme-radius 0 0;
        max-height: 500px;

        @include media-breakpoint-between(md,xl){
            width: 80%;
            object-fit: cover;
            object-position: top;
        }
    }

    &__header {
        font-size: 2.5rem;
        margin-top: 3rem;
        color: var(--theme-color-blue);
        
        @include media-breakpoint-up(lg){
            font-size: 4rem;
            margin-top: 5rem;
        }
    }

    &__desc {
        font-size: 1.25rem;
        line-height: 1.3;
        margin-top: .75rem;
        
        @include media-breakpoint-up(sm){
            font-size: 1.5rem;
        }
    }
}

.contact-form {
    position: relative;
    margin-top: -6rem;
    z-index: 6;
    padding: 8rem 0 6rem;
    background-image: url('./../img/svg/contact-bg.svg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    
    @include media-breakpoint-up(md){
        padding: 10rem 0;
    }

    &__desc {
        font-size: 1.25rem;
        line-height: 1.3;
        
        @include media-breakpoint-up(lg){
            font-size: 1.5rem;
        }
    }

    &__links {
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 1.6;
        margin: 1rem 0;
        
        @include media-breakpoint-up(sm){
            font-size: 1.75rem;
        }

        a {
            display: block;
            transition: color .2s ease-in-out;

            &:hover {
                color: var(--theme-color-blue);
            }
        }
    }

    &__socials {
        display: flex;
        flex-wrap: wrap;
        gap: 1.5rem;
        justify-content: center;
        align-items: center;

        a {
            svg {
                transition: .3s transform cubic-bezier(0.3, 0.6, 0.8, 1);
            }

            &:hover {
                svg {
                    transform: scale(1.18);
                }
            }
        }

        @include media-breakpoint-up(lg){
            justify-content: flex-start;
        }
    }
}

.contact-team {
    padding: 1.5rem 0 0;
    
    @include media-breakpoint-up(lg){
        padding: 5rem 0;
    }
    
    &__desc {
        font-size: 1.25rem;
        line-height: 1.3;

        @include media-breakpoint-up(lg){
            font-size: 1.5rem;
        }
    }

    .contact-card {
        position: relative;
        display: block;
        align-items: center;
        padding: 1.75rem 1rem .5rem; 
        max-width: 550px;
        margin-left: auto;
        margin-right: auto;
        gap: 1.25rem;
        border-radius: $theme-radius;
        background-color: $white;
        box-shadow: 0px 10px 30px #660DFF29;
        margin-bottom: 3rem;
        
        @include media-breakpoint-up(md){
            padding: 1.75rem 2.5rem .5rem; 
        }
        @include media-breakpoint-up(xl){
            max-width: initial;
            margin-top: 0;
            margin-bottom: 2rem;
        }

        &__position {
            font-size: 0.875rem;
        }

        &__title {
            font-size: 1.25rem;
            line-height: 1.2;
            font-weight: 700;
            color: var(--theme-color-blue);
            margin-bottom: .5rem;
            
            @include media-breakpoint-up(xl){
                font-size: 1.5rem;
            }
            @include media-breakpoint-up(xxl){
                font-size: 1.75rem;
            }
        }

        &__desc {
            font-size: .75rem;
            margin-bottom: 1rem;
        }

        &__img {
            position: absolute;
            top: -6rem;
            left: 0;
            right: 0;
            margin: auto;
            height: 160px;
            max-width: 160px;
            border-radius: $theme-radius;
            overflow: hidden;
            
            @include media-breakpoint-up(xl){
                top: 0;
                border-radius: 0;
                position: relative;
                max-width: initial;
                height: 240px;
            }

            img {
                width: 100%;
                height: 100%;
                object-position: center;
                object-fit: cover;
            }
        }

        &__footer {
            display: flex;
            flex-direction: column;
            word-break: break-all;
            gap: .5rem;
            font-size: .875rem;
            font-weight: 600;
            margin-bottom: 1.5rem;
        }
    }
}

.contact-map {
    padding: 6rem 0;
    overflow: hidden;
    background-image: url('./../img/svg/cta-bg.svg');
    background-repeat: no-repeat;
    background-position: center 100px;
    background-size: cover;
    
    @include media-breakpoint-up(sm){
        padding: 6rem 0 8rem;
        background-position: center bottom;
    }
    @include media-breakpoint-up(lg){
        padding: 4rem 0 8rem;
        background-position: center 200px;
    }

    .acf-map {
        iframe {
            overflow: hidden;
            border-radius: $theme-radius;
            box-shadow: 10px 20px 40px #0A21E841;
        }
    }

    &__row {
        margin-top: -4rem;
        margin-bottom: 4rem;
        
        @include media-breakpoint-up(sm){
            margin-bottom: 6rem;
        }
    }
}