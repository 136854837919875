.sidebar {
    padding: 1.5rem 1rem;
    background-color: #fff;
    border-radius: $theme-radius;
    box-shadow: 0px 3px 30px #660DFF29;

    @include media-breakpoint-up(lg){
        position: sticky;
        top: 6rem;
    }

    &__title {
        font-size: 1.25rem;
        
        @include media-breakpoint-up(xxl){
            font-size: 1.5rem;
        }
    }

    &__search {
        margin: .75rem 0;

        input {
            border-radius: 3rem;
        }
    }

    &__sort {
        display: flex;
        justify-content: space-between;
        font-size: .875rem;
        flex-wrap: wrap;
        gap: .25rem;
        color: var(--theme-color-gray-70);
        text-transform: uppercase;

        @include media-breakpoint-up(xxl){
            font-size: .95rem;
            gap: .5rem;
        }

        button {
            all: unset;
            cursor: pointer;
            transition: color .3s ease-in-out;


            &.active {
                color: var(--theme-color-azure);
            }
        }
    }

    &__list {
        ul {
            margin-top: 1.5rem;

            li {
                line-height: 1.2;
                margin-bottom: 0.45rem;
                list-style-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.50059 0L6.17793 1.32148L11.4094 6.56243H0V8.43757H11.4094L6.17793 13.6785L7.49941 15L15 7.49941L7.50059 0Z' fill='%230A21E8'/%3E%3C/svg%3E%0A");
                transition: color .3s ease-in-out;

                &:hover {
                    color: var(--theme-color-azure);
                }
            }
        }
    }
}